import { RouteComponentProps } from '@reach/router';
import { UsageLoggerContextProvider } from '../../externalComponents/context/usageLoggerContext/usageLoggerContext';
import { InstantOnApplication } from './instantOnApplication';
import { AppPermissionsBoundary } from '../../components/appPermissionsBoundary/appPermissionsBoundary';

export interface InstantOnApplicationWrapperProps extends RouteComponentProps {
  projectId: string;
}

export const InstantOnApplicationWrapper = (
  props: InstantOnApplicationWrapperProps
) => {
  const { projectId } = props;

  return (
    <UsageLoggerContextProvider>
      <AppPermissionsBoundary projectId={projectId}>
        <InstantOnApplication projectId={projectId} />
      </AppPermissionsBoundary>
    </UsageLoggerContextProvider>
  );
};
