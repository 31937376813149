import { BuddiContextProvider } from '../buddiContext/buddiContext';
import { BentleyContextProvider } from '../bentleyContext/bentleyContext';
import { FeatureToggleContextProvider } from '../featureToggleContext/featureToggleContext';
import { IModelPermissionContextProvider } from '../iModelPermissionContext/iModelPermissionContext';
import { UsageLoggerContextProvider } from '../usageLoggerContext/usageLoggerContext';
import { UserContextProvider } from '../userContext/userContext';
import { SynchronizationContextProps } from '../../typedef/index';
import { ToastContextProvider } from '../toastContext/toastContext';
import { ErrorHandlingContextProvider } from '../errorHandlingContext/errorHandlingContext';
import { ConnectionDefintionsContextProvider } from '../connectionDefinitionsContext/connectionsDefintionsContext';
import { SpatialRootsContextProvider } from '../spatialRootsContext/spatialRootsContext';
import { IModelLevelPermissionContextProvider } from '../iModelLevelPermissionContext/iModelLevelPermissionContext';
import { RBACPermissionContextProvider } from '../rbacPermissionContext/rbacPermissionContext';
import { InputFilesContextProvider } from '../inputFilesContext/inputFilesContext';
import { toaster } from '@itwin/itwinui-react/cjs/core';
import React from 'react';

export const SynchronizationContext = (props: SynchronizationContextProps) => {
  const {
    children,
    buddiUrls,
    projectId,
    imodelId,
    launchDarkleyClientId,
    user,
    onUserUnauthenticated,
    onErrorEncountered,
  } = props;

  return (
    <ToastContextProvider toaster={toaster}>
      <BentleyContextProvider projectId={projectId} iModelId={imodelId}>
        <UserContextProvider user={user}>
          <BuddiContextProvider buddiUrls={buddiUrls}>
            <FeatureToggleContextProvider
              launchDarkleyClientId={launchDarkleyClientId}
            >
              <RBACPermissionContextProvider>
                <IModelLevelPermissionContextProvider>
                  <IModelPermissionContextProvider>
                      <UsageLoggerContextProvider>
                        <ErrorHandlingContextProvider
                          onErrorEncountered={onErrorEncountered}
                          onUserUnauthenticated={onUserUnauthenticated}
                        >
                          <ConnectionDefintionsContextProvider>
                            <SpatialRootsContextProvider>
                              <InputFilesContextProvider>
                                {children}
                              </InputFilesContextProvider>
                            </SpatialRootsContextProvider>
                          </ConnectionDefintionsContextProvider>
                        </ErrorHandlingContextProvider>
                      </UsageLoggerContextProvider>
                  </IModelPermissionContextProvider>
                </IModelLevelPermissionContextProvider>
              </RBACPermissionContextProvider>
            </FeatureToggleContextProvider>
          </BuddiContextProvider>
        </UserContextProvider>
      </BentleyContextProvider>
    </ToastContextProvider>
  );
};
