import React, { ProviderProps } from 'react';
import { useState, useEffect } from 'react';
import { createUserManager } from '../../externalComponents/services/userManager';
import { UserManager, User } from 'oidc-client';
import { FullPageLoader } from '../../externalComponents/components/fullPageLoader/fullPageLoader';
import { createForgeUserManager } from '../../externalComponents/services/forgeUserManager';
import { useTranslation } from 'react-i18next';
import { parse } from 'query-string';

export interface Configuration {
  authority: string;
  clientId: string;
  launchDarkleyClientId: string;
  appInsightsInstrumentationKey: string;
  buddiRegion: string;
  buddiUrls: {
    connectPortalUrl: string;
    documentServiceUrl: string;
    iModelHubUrl: string;
    iModelsAPIsUrl: string;
    iModelHubPortal: string;
    productSettingsServiceUrl: string;
    orchestrationServiceUrl: string;
    iModelBridgeSchedulerUrl: string;
    connectedContextUrl: string;
    plantSightUrl: string;
    badgersUrl: string;
    iModelBridgeService: string;
    rbacServiceUrl: string;
    projectSharePortalUrl: string;
    projectWise365Url: string;
  };
}

export interface IAppContext {
  user: User | null;
  setUser: (user: User) => any;
  userManager: UserManager;
  configuration: Configuration;
  forgeUser: User | null;
  setForgeUser: (user: User) => any;
  forgeUserManager: UserManager;
  isIFramed: boolean;
}

export const AppContext = React.createContext<IAppContext>({
  user: null,
  setUser: () => {},
  userManager: {} as any,
  configuration: {} as Configuration,
  forgeUser: null,
  setForgeUser: () => {},
  forgeUserManager: {} as any,
  isIFramed: false,
});

export interface AppContextProviderProps
  extends Omit<ProviderProps<IAppContext>, 'value'> {}

async function getConfigurationFromBackend(applicationParam: string) {
  return (await (
    await fetch(`/api/Configurations/Configuration/${applicationParam}`)
  ).json()) as Configuration;
}

export function AppContextProvider({ ...props }: AppContextProviderProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [userManager, setUserManager] = useState<UserManager>({} as any);
  const [forgeUser, setForgeUser] = useState<User | null>(null);

  const [forgeUserManager, setForgeUserManager] = useState<UserManager>(
    {} as any
  );
  const [configuration, setConfiguration] = useState<Configuration>(
    {} as Configuration
  );
  const [isIFramed, SetIsIFramed] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    loadAppContext();

    async function loadAppContext() {
      let application = '';
      if (window.location.pathname === '/signin-oidc') {
        application = localStorage.getItem('application') as string;
      } else {
        const windowQueryParams = parse(window.location?.search);
        const app = (windowQueryParams?.application as string) || 'InstantOn';
        localStorage.setItem('application', app);
        application = app;
      }

      const recievedConfiguration = await getConfigurationFromBackend(
        application
      );
      setConfiguration(recievedConfiguration);

      const forgeManager = createForgeUserManager();
      const fUser = await forgeManager.getUser();
      setForgeUser(fUser);
      setForgeUserManager(forgeManager);

      const manager = createUserManager(
        recievedConfiguration.authority,
        recievedConfiguration.clientId
      );
      const user = await manager.getUser();
      setUser(user);
      setUserManager(manager);
      setIsLoaded(true);
      SetIsIFramed(window.self !== window.top);
    }
  }, []);

  return isLoaded ? (
    <AppContext.Provider
      value={{
        user,
        userManager,
        configuration,
        setUser,
        forgeUser,
        forgeUserManager,
        setForgeUser,
        isIFramed,
      }}
      {...props}
    />
  ) : (
    <FullPageLoader>{t('FullPage_Loading_Message')}</FullPageLoader>
  );
}

export function useAppContext() {
  const context = React.useContext(AppContext);
  if (context == null) {
    throw new Error('useAppContext must be used inside provider');
  }
  return context;
}
