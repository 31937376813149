import { UserManager } from 'oidc-client';

const scopes =
  'openid email feature_tracking projectwise-share projectwise-documents-2766 imodelhub product-settings-service rbac-service' +
  ' cloud-orchestration-service-2566 context-registry-service federated-design-data-service-for-itwin-resource badgers itwinjs' +
  ' imodel-bridge-service-api-general projectwise-web-connections-service imodels:modify imodels:read';

export const createUserManager = (authority: string, client_id: string) => {
  return new UserManager({
    authority,
    client_id,
    redirect_uri: `${window.location.origin}/signin-oidc`,
    silent_redirect_uri: `${window.location.origin}/silent-signin-oidc`,
    automaticSilentRenew: true,
    response_type: 'code',
    query_status_response_type: 'code',
    scope: scopes,
    post_logout_redirect_uri: `${window.location.origin}/signout-oidc`,
    // accessTokenExpiringNotificationTime: 3540,
  });
};
